
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { DataTableHeader } from "@/global-components/data-table/types";
import { ListCategories } from "@/services/v1/admin/learning/categories/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { gpf } from "@/utils/global-functions";
import { listStatusFilter } from "@/utils/global-variables";
import { helper as $h } from "@/utils/helper";
import {
  popupConfirmation,
  popupConfirmationHide,
} from "@/utils/popup/confirmation";
import { popupStatus } from "@/utils/popup/status";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";

const TextField = defineAsyncComponent(
  () => import(`@/global-components/input/text-field/Main.vue`)
);

const Autocomplete = defineAsyncComponent(
  () => import(`@/global-components/input/autocomplete/Main.vue`)
);

const ChipActiveInactive = defineAsyncComponent(
  () => import(`@/global-components/chip/active-inactive.vue`)
);

const TooltipActiveInactive = defineAsyncComponent(
  () => import(`@/global-components/tooltip/active-inactive.vue`)
);

const ModalFormlCategory = defineAsyncComponent(
  () => import(`./components/modal-form-category.vue`)
);

const ModalDetailCategory = defineAsyncComponent(
  () => import(`./components/modal-detail-category.vue`)
);

const servicesV1AdminLearningCategories = async () =>
  import(`@/services/v1/admin/learning/categories`);

export default defineComponent({
  components: {
    TextField,
    Autocomplete,
    ChipActiveInactive,
    TooltipActiveInactive,
    ModalFormlCategory,
    ModalDetailCategory,
  },
  setup() {
    // START === setup breadcrumb =====================================================
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Data Kategori",
        to: `${dashboardPath}/data-categories`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    const tableHeaders = [
      {
        text: "Banner",
        value: "banner",
        itemClass: "w-36",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Created At",
        value: "createdAt",
        headerClass: "w-32",
      },
      {
        text: "Action",
        value: "action",
        itemClass: "w-20",
        align: "center",
      },
    ] as DataTableHeader[];

    const dataPage = reactive({
      data: [] as any,
      loading: false,

      // for pagination
      page: 1,
      pageSize: 10,
      totalData: 1,
    });

    const queryParams = reactive({
      search: "",
      isActive: "all",
    });

    // START === Functions for handle list category data =========================================
    const refetchListCategories = ref(false as boolean);
    const getListCategories = async () => {
      const {
        getAdminLearningCategories,
      } = await servicesV1AdminLearningCategories();

      dataPage.loading = true;

      const page = dataPage.page;
      const pageSize = dataPage.pageSize;

      const params = {
        search: queryParams.search,
        isActive: queryParams.isActive,
        page,
        pageSize,
      } as any;

      if (queryParams.isActive === "all") params.isActive = null;

      try {
        const hit = await getAdminLearningCategories({ params });
        const ds = hit.diagnostic;
        const rs = hit.response;
        // console.log("response get dm ", rs);

        dataPage.data = rs;
        dataPage.totalData = ds.totalData as number;

        // back to default refetch
        refetchListCategories.value = false;
      } catch (err: any) {
        console.error("err getListCategories Admin ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        dataPage.loading = false;
      }
    };

    const dataCategories = computed(() =>
      dataPage.data.map((category: ListCategories) => ({
        ...category,
        status: category.isActive ? "Active" : "Inactive",
      }))
    );

    onMounted(() => {
      getListCategories();
    });

    const handleSearch = () => {
      dataPage.page = 1;
      getListCategories();
    };

    watch(
      () => dataPage.pageSize,
      () => {
        dataPage.page = 1;
        getListCategories();
      }
    );

    watch(
      () => dataPage.page,
      () => {
        getListCategories();
      }
    );

    // for refetch list category data
    watch(
      () => refetchListCategories.value,
      (newVal) => {
        if (newVal) getListCategories();
      }
    );
    // END === Functions for handle list category data ===========================================

    // START === add new category ============================================================
    const setupFormCategory = reactive({
      show: false,
      id: 0,
      code: "",
    });

    const addNewCategory = () => {
      setupFormCategory.show = true;
    };

    const editCategory = (category: any) => {
      setupFormCategory.show = true;
      setupFormCategory.id = category.id;
      setupFormCategory.code = category.kode;
    };
    // END === add new category ==============================================================

    // START === Function for show modal detail category =====================================
    const setupDetailCategory = reactive({
      show: false,
      code: "",
    });

    const showModalDetailCategory = async (category: any) => {
      setupDetailCategory.show = true;
      setupDetailCategory.code = category?.kode || "";
    };
    // END === Function for show modal detail category =======================================

    // START === Function for delete category ================================================
    const deleteCategoryPopup = (data: any): void => {
      popupConfirmation({
        title: "Hapus Data Kategori",
        message: `Apakah Anda yakin, ingin menghapus data dengan title : "${data.title}"`,
        buttons: [
          {
            type: "outline",
            text: "Batal",
            action: popupConfirmationHide,
          },
          {
            type: "danger",
            text: "Hapus",
            action: () => {
              fixDeleteCategory(data.kode);
              popupConfirmationHide();
            },
          },
        ],
      }).show();
    };

    const fixDeleteCategory = async (categoryCode: string) => {
      const {
        deleteAdminLearningCategoryById,
      } = await servicesV1AdminLearningCategories();

      gpf.gLoading.show();

      try {
        const hit = await deleteAdminLearningCategoryById(categoryCode);

        const messageSuccess = hit?.diagnostic?.message;

        popupStatus({
          type: "s",
          message: messageSuccess,
        }).show();
      } catch (err: any) {
        console.error("err fixDeleteCategory ", err, err?.response);
        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();

        // refetch list data-category
        getListCategories();
      }
    };
    // END === Function for delete category ==================================================

    return {
      tableHeaders,
      getListCategories,
      dataPage,
      queryParams,
      listStatusFilter,
      handleSearch,
      dataCategories,
      refetchListCategories,

      setupFormCategory,
      addNewCategory,
      editCategory,

      $h,
      gpf,
      setupDetailCategory,
      showModalDetailCategory,

      deleteCategoryPopup,
      fixDeleteCategory,
    };
  },
});
