export const listStatusFilter = [
  {
    text: "Tampilkan Semua",
    value: "all",
  },
  {
    text: "Active",
    value: true,
  },
  {
    text: "Inactive",
    value: false,
  },
];
